/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";

// index page sections
import Hero from "./IndexSections/Hero.jsx";
import Buttons from "./IndexSections/Buttons.jsx";
import Inputs from "./IndexSections/Inputs.jsx";
import CustomControls from "./IndexSections/CustomControls.jsx";
import Menus from "./IndexSections/Menus.jsx";
import Navbars from "./IndexSections/Navbars.jsx";
import Tabs from "./IndexSections/Tabs.jsx";
import Progress from "./IndexSections/Progress.jsx";
import Pagination from "./IndexSections/Pagination.jsx";
import Pills from "./IndexSections/Pills.jsx";
import Labels from "./IndexSections/Labels.jsx";
import Alerts from "./IndexSections/Alerts.jsx";
import Typography from "./IndexSections/Typography.jsx";
import Modals from "./IndexSections/Modals.jsx";
import Datepicker from "./IndexSections/Datepicker.jsx";
import TooltipPopover from "./IndexSections/TooltipPopover.jsx";
import Carousel from "./IndexSections/Carousel.jsx";
import Icons from "./IndexSections/Icons.jsx";
import Login from "./IndexSections/Login.jsx";
import Download from "./IndexSections/Download.jsx";

class Index extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

    render() {
        return (
            <>
                <DemoNavbar/>
                <main ref="main">
                    <Hero/>
                    {/*<Buttons/>*/}
                    {/*<Inputs/>*/}
                    {/*<section className="section">*/}
                        {/*<Container>*/}
                            {/*<CustomControls/>*/}
                            {/*<Menus/>*/}
                        {/*</Container>*/}
                    {/*</section>*/}
                    {/*<Navbars/>*/}
                    {/*<section className="section section-components">*/}
                        {/*<Container>*/}
                            {/*<Tabs/>*/}
                            {/*<Row className="row-grid justify-content-between align-items-center mt-lg">*/}
                                {/*<Progress/>*/}
                                {/*<Pagination/>*/}
                            {/*</Row>*/}
                            {/*<Row className="row-grid justify-content-between">*/}
                                {/*<Pills/>*/}
                                {/*<Labels/>*/}
                            {/*</Row>*/}
                            {/*<Alerts/>*/}
                            {/*<Typography/>*/}
                            {/*<Modals/>*/}
                            {/*<Datepicker/>*/}
                            {/*<TooltipPopover/>*/}
                        {/*</Container>*/}
                    {/*</section>*/}
                    <Carousel
                        link="https://discord.gg/7Ck3bET"
                        actionLabel="Join RLH Unity"
                        logo="assets/img/RLHUnity.png"
                        image="assets/img/RLHUnityCover.jpg"
                        label="Join our E-sports Community"
                        description="RLH Unity is a competitive Rocket League team sponsored by Rocket League Hub."
                    />
                    <Carousel
                        link="https://gamersrdy.com"
                        actionLabel="Visit Website"
                        logo="https://www.gamersrdy.com/wp-content/uploads/2018/09/gamersrdy-white.png"
                        image="https://i.imgur.com/OkinBY5.png"
                        label="Featured Partner"
                        description="GamersRdy is a community built around helping Rocket League and CS:GO players get better. You'll find experienced coaches (for personal 1 to 1 coaching sessions) as well as regular content being published (news, guides, opinion) all within a friendly yet competitive community."
                    />
                    {/*<Icons/>*/}
                    {/*<Login/>*/}
                    {/*<Download/>*/}
                </main>
                <CardsFooter/>
            </>
        );
    }
}

export default Index;
