/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from "react-router-dom";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

// reactstrap components
import {
    Button,
    UncontrolledCollapse,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

class DemoNavbar extends React.Component {
    componentDidMount() {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
    }

    render() {
        return (
            <>
                <header className="header-global">
                    <Navbar
                        className="navbar-main navbar-transparent navbar-light headroom"
                        expand="lg"
                        id="navbar-main"
                    >
                        <Container>
                            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                                <img
                                    alt="..."
                                    src={require("assets/img/brand/rlh-logo.png")}
                                />
                            </NavbarBrand>
                            <button className="navbar-toggler" id="navbar_global">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <UncontrolledCollapse navbar toggler="#navbar_global">
                                <div className="navbar-collapse-header">
                                    <Row>
                                        <Col className="collapse-brand" xs="6">
                                            <Link to="/">
                                                <img
                                                    alt="..."
                                                    src={require("assets/img/brand/rlh-logo.png")}
                                                />
                                            </Link>
                                        </Col>
                                        <Col className="collapse-close" xs="6">
                                            <button className="navbar-toggler" id="navbar_global">
                                                <span/>
                                                <span/>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                                    {/*<UncontrolledDropdown nav>*/}
                                        {/*<DropdownToggle nav>*/}
                                            {/*<i className="ni ni-ui-04 d-lg-none mr-1"/>*/}
                                            {/*<span className="nav-link-inner--text">Components</span>*/}
                                        {/*</DropdownToggle>*/}
                                        {/*<DropdownMenu className="dropdown-menu-xl">*/}
                                            {/*<div className="dropdown-menu-inner">*/}
                                                {/*<Media*/}
                                                    {/*className="d-flex align-items-center"*/}
                                                    {/*href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/overview?ref=adsr-navbar"*/}
                                                    {/*target="_blank"*/}
                                                {/*>*/}
                                                    {/*<div*/}
                                                        {/*className="icon icon-shape bg-gradient-primary rounded-circle text-white">*/}
                                                        {/*<i className="ni ni-spaceship"/>*/}
                                                    {/*</div>*/}
                                                    {/*<Media body className="ml-3">*/}
                                                        {/*<h6 className="heading text-primary mb-md-1">*/}
                                                            {/*Getting started*/}
                                                        {/*</h6>*/}
                                                        {/*<p className="description d-none d-md-inline-block mb-0">*/}
                                                            {/*Learn how to use Argon compiling Scss, change*/}
                                                            {/*brand colors and more.*/}
                                                        {/*</p>*/}
                                                    {/*</Media>*/}
                                                {/*</Media>*/}
                                                {/*<Media*/}
                                                    {/*className="d-flex align-items-center"*/}
                                                    {/*href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/colors?ref=adsr-navbar"*/}
                                                    {/*target="_blank"*/}
                                                {/*>*/}
                                                    {/*<div*/}
                                                        {/*className="icon icon-shape bg-gradient-success rounded-circle text-white">*/}
                                                        {/*<i className="ni ni-palette"/>*/}
                                                    {/*</div>*/}
                                                    {/*<Media body className="ml-3">*/}
                                                        {/*<h6 className="heading text-primary mb-md-1">*/}
                                                            {/*Foundation*/}
                                                        {/*</h6>*/}
                                                        {/*<p className="description d-none d-md-inline-block mb-0">*/}
                                                            {/*Learn more about colors, typography, icons and the*/}
                                                            {/*grid system we used for Argon.*/}
                                                        {/*</p>*/}
                                                    {/*</Media>*/}
                                                {/*</Media>*/}
                                                {/*<Media*/}
                                                    {/*className="d-flex align-items-center"*/}
                                                    {/*href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alert?ref=adsr-navbar"*/}
                                                    {/*target="_blank"*/}
                                                {/*>*/}
                                                    {/*<div*/}
                                                        {/*className="icon icon-shape bg-gradient-warning rounded-circle text-white">*/}
                                                        {/*<i className="ni ni-ui-04"/>*/}
                                                    {/*</div>*/}
                                                    {/*<Media body className="ml-3">*/}
                                                        {/*<h5 className="heading text-warning mb-md-1">*/}
                                                            {/*Components*/}
                                                        {/*</h5>*/}
                                                        {/*<p className="description d-none d-md-inline-block mb-0">*/}
                                                            {/*Browse our 50 beautiful handcrafted components*/}
                                                            {/*offered in the Free version.*/}
                                                        {/*</p>*/}
                                                    {/*</Media>*/}
                                                {/*</Media>*/}
                                            {/*</div>*/}
                                        {/*</DropdownMenu>*/}
                                    {/*</UncontrolledDropdown>*/}
                                    {/*<UncontrolledDropdown nav>*/}
                                        {/*<DropdownToggle nav>*/}
                                            {/*<i className="ni ni-collection d-lg-none mr-1"/>*/}
                                            {/*<span className="nav-link-inner--text">Trading</span>*/}
                                        {/*</DropdownToggle>*/}
                                        {/*<DropdownMenu>*/}
                                            {/*<DropdownItem to="/landing" tag={Link}>*/}
                                                {/*Trades*/}
                                            {/*</DropdownItem>*/}
                                            {/*<DropdownItem to="/profile" tag={Link}>*/}
                                                {/*Items*/}
                                            {/*</DropdownItem>*/}
                                        {/*</DropdownMenu>*/}
                                    {/*</UncontrolledDropdown>*/}
                                    {/*<UncontrolledDropdown nav>*/}
                                        {/*<DropdownToggle nav>*/}
                                            {/*<i className="ni ni-collection d-lg-none mr-1"/>*/}
                                            {/*<span className="nav-link-inner--text">Matchmaking</span>*/}
                                        {/*</DropdownToggle>*/}
                                        {/*<DropdownMenu>*/}
                                            {/*<DropdownItem to="/lfg/group" tag={Link}>*/}
                                                {/*Looking for Group*/}
                                            {/*</DropdownItem>*/}
                                            {/*<DropdownItem to="/lfg/team" tag={Link}>*/}
                                                {/*Looking for Team*/}
                                            {/*</DropdownItem>*/}
                                            {/*<DropdownItem to="/lfg/ranked" tag={Link}>*/}
                                                {/*Looking for Ranked*/}
                                            {/*</DropdownItem>*/}
                                        {/*</DropdownMenu>*/}
                                    {/*</UncontrolledDropdown>*/}
                                </Nav>
                                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://twitter.com/RLHubOfficial"
                                            id="tooltip184698705"
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitter-square"/>
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Twitter
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip delay={0} target="tooltip184698705">
                                            Follow us on Twitter
                                        </UncontrolledTooltip>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://www.twitch.tv/RLHubOfficial"
                                            id="tooltip112445449"
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitch"/>
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Twitch
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip delay={0} target="tooltip112445449">
                                            Follow us on Twitch
                                        </UncontrolledTooltip>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://www.instagram.com/rlh.official"
                                            id="tooltip356693867"
                                            target="_blank"
                                        >
                                            <i className="fa fa-instagram"/>
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Instagram
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip delay={0} target="tooltip356693867">
                                            Follow us on Instagram
                                        </UncontrolledTooltip>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://www.facebook.com/TheRocketLeagueHub"
                                            id="tooltip333589074"
                                            target="_blank"
                                        >
                                            <i className="fa fa-facebook-square"/>
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Facebook
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip delay={0} target="tooltip333589074">
                                            Like us on Facebook
                                        </UncontrolledTooltip>
                                    </NavItem>
                                    <NavItem className="d-none d-lg-block ml-lg-4">
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="default"
                                            href="https://discordapp.com/invite/tP334F2"
                                            // target="_blank"
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="ni ni-chat-round"/>
                                            </span>
                                            <span className="nav-link-inner--text ml-1">
                                                Join Discord
                                            </span>
                                        </Button>
                                    </NavItem>
                                </Nav>
                            </UncontrolledCollapse>
                        </Container>
                    </Navbar>
                </header>
            </>
        );
    }
}

export default DemoNavbar;
