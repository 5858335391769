/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";

class Hero extends React.Component {
    render() {
        return (
            <>
                <div className="position-relative">
                    <div
                        style={{
                            position : 'absolute',
                            width : '100%',
                            height : '100%'
                        }}
                    >
                        <video style={{
                            objectFit : 'cover',
                            width : '100%',
                            height : '100%',
                            opacity : '0.1'
                        }} autoPlay muted loop playsInline>
                            <source src="assets/video/clip.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    {/* Hero for FREE version */}
                    <section className="section section-lg section-hero section-shaped">
                        {/* Background circles */}
                        <div className="shape shape-default"/>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="10">
                                        <img
                                            alt="..."
                                            className="img-fluid"
                                            src={require("assets/img/brand/rlh-logo.png")}
                                            style={{width: "200px"}}
                                        />
                                        <p className="lead text-white">
                                            Rocket League Hub is a fan-made community with thousands of active Rocket
                                            League players.
                                            Join the official Rocket League Hub Discord with over 30,000+ members and up
                                            to 10,000+ online at peak!
                                        </p>
                                        <div className="btn-wrapper mt-5">
                                            <Button
                                                className="btn-white btn-icon mb-3 mb-sm-0"
                                                color="default"
                                                href="https://discordapp.com/invite/tP334F2"
                                                size="lg"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                  <i className="ni ni-chat-round"/>
                                                </span>
                                                <span className="btn-inner--text">Join Discord</span>
                                            </Button>{" "}
                                            {/*<Button*/}
                                                {/*className="btn-icon mb-3 mb-sm-0"*/}
                                                {/*color="default"*/}
                                                {/*href="/trade"*/}
                                                {/*size="lg"*/}
                                                {/*// target="_blank"*/}
                                            {/*>*/}
                                                {/*<span className="btn-inner--icon mr-1">*/}
                                                  {/*<i className="fa fa-key"/>*/}
                                                {/*</span>*/}
                                                {/*<span className="btn-inner--text">*/}
                                                  {/*Browse Trades*/}
                                                {/*</span>*/}
                                            {/*</Button>*/}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        {/*<div className="separator separator-bottom separator-skew zindex-100">*/}
                            {/*<svg*/}
                                {/*xmlns="http://www.w3.org/2000/svg"*/}
                                {/*preserveAspectRatio="none"*/}
                                {/*version="1.1"*/}
                                {/*viewBox="0 0 2560 100"*/}
                                {/*x="0"*/}
                                {/*y="0"*/}
                            {/*>*/}
                                {/*<polygon*/}
                                    {/*className="fill-white"*/}
                                    {/*points="2560 0 2560 100 0 100"*/}
                                {/*/>*/}
                            {/*</svg>*/}
                        {/*</div>*/}
                        <div style={{
                            position : 'absolute',
                            bottom : '12px',
                            width : '100%',
                            textAlign : 'center'
                            // left : 'calc(50% - 24px)'
                        }}>
                            <span
                                onClick={ () => document.getElementById('partner-section').scrollIntoView()  }
                                style={{
                                    color : 'rgba(255, 255, 255, 0.35)',
                                    // width : '48px',
                                    fontSize : '32px',
                                    cursor : 'pointer'
                                }}
                            >
                                <i className="fa fa-chevron-down bounce"/>
                            </span>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Hero;
