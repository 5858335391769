/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from 'prop-types';

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: "https://i.imgur.com/OkinBY5.png",
    altText: "",
    caption: "",
    header: ""
  }
];

class Carousel extends React.Component {
  render() {
    const {
      logo,
      image,
      label,
      description,
      link,
      actionLabel
    } = this.props;

    return (
      <>
        <section className="section section-dark" id="partner-section">
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h7 className="text-white font-weight-bold">
                  {label}
                </h7>
                {
                  logo &&
                  <Row>
                    <img
                        src={logo}
                        style={{
                          height : '30px',
                          marginLeft : '14px'
                        }}
                    />
                  </Row>
                }
                <p className="lead text-white mt-4">
                  {description}
                </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href={link}
                  target="_blank"
                >
                  {actionLabel || 'Visit Website'}
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={[{src:image, altText:'',caption:'',header:''}]} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

Carousel.propTypes = {
  logo : PropTypes.string,
  image : PropTypes.string.isRequired,
  label : PropTypes.string.isRequired,
  description : PropTypes.string.isRequired,
  link : PropTypes.string.isRequired,
  actionLabel : PropTypes.string
};

export default Carousel;
