/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";

class Trades extends React.Component {
    render() {
        return (
            <>
                <div className="position-relative">
                    {/* Hero for FREE version */}
                    <section className="section section-shaped">
                        {/* Background circles */}
                        <div className="shape shape-default"/>
                        <Container className="shape-container d-flex align-items-center py-md">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center" lg="10">
                                        <p className="lead text-white">
                                            Browse thousands of trades in real-time, sync your Discord server with our trade bot, and get notified when prices fluctuate!
                                        </p>
                                        <div className="btn-wrapper mt-5">
                                            <Button
                                                className="btn-white btn-icon mb-3 mb-sm-0"
                                                color="default"
                                                href="https://discordapp.com/invite/tP334F2"
                                                size="lg"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                  <i className="ni ni-chat-round"/>
                                                </span>
                                                <span className="btn-inner--text">Join Discord</span>
                                            </Button>{" "}
                                            <Button
                                                className="btn-icon mb-3 mb-sm-0"
                                                color="default"
                                                href="/trade"
                                                size="lg"
                                                // target="_blank"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                  <i className="fa fa-key"/>
                                                </span>
                                                <span className="btn-inner--text">
                                                  Browse Trades
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                </div>
            </>
        );
    }
}

export default Trades;
